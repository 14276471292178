export const validatorPositive = value => {
  if (value >= 0) {
    return true
  }
  return false
}

export const validatorPassword = password => {
  /* eslint-disable no-useless-escape */
  const regExp = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).+$/
  /* eslint-enable no-useless-escape */
  const validPassword = regExp.test(password)
  return validPassword
}

export const validatorName = name => {
  /* eslint-disable no-useless-escape */
  const regExp = /^[a-zA-Z\s]+$/
  /* eslint-enable no-useless-escape */
  const validName = regExp.test(name)
  return validName
}

export const validatorDOB = dob => {
  const today = new Date()
  const birthDate = new Date(dob)
  let age = today.getFullYear() - birthDate.getFullYear()
  const m = today.getMonth() - birthDate.getMonth()
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--
  }
  return age >= 18
}
export const validatorLimit1000Characters = text => {
  const regex = /<[^>]*>/g
  return text.replace(regex, '').length <= 10000
}

export const validatorPhoneNumber = phoneNumber => {
  /* eslint-disable no-useless-escape */
  const regExp = /^0[0-9]{9}$/
  /* eslint-enable no-useless-escape */
  const validPhoneNumber = regExp.test(phoneNumber)
  return validPhoneNumber
}

export const validatorCreditCard = creditnum => {
  /* eslint-disable no-useless-escape */
  const cRegExp = /^(?:3[47][0-9]{13})$/
  /* eslint-enable no-useless-escape */
  const validCreditCard = cRegExp.test(creditnum)
  return validCreditCard
}

export const validatorUrlValidator = val => {
  if (val === undefined || val === null || val.length === 0) {
    return true
  }
  /* eslint-disable no-useless-escape */
  const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
  /* eslint-enable no-useless-escape */
  return re.test(val)
}
