<template>
  <b-row class="mb-1 d-flex align-items-center">
    <b-col
      cols="12"
    >
      <b-row
        class="mb-2"
        style="max-height: 100px; overflow-y: scroll;"
      >

        <b-col
          v-for="(i, ind) in selectedUsers"
          :key="ind"
          md="12"
        >
          <b-badge
            pill
            variant="light-success"
          >
            {{ `${i.groupId}. ${i.name}` }}
          </b-badge>
        </b-col>
      </b-row>
      <!-- <div
        style="display: flex; flex-direction: column; gap: 2px;"
      >

        <div
          v-for="(i, ind) in selectedUsers"
          :key="ind"
        >

          <b-badge
            pill
            variant="light-success"
          >
            {{ i.username }}
          </b-badge>
        </div>

      </div> -->
      <b-button
        v-if="csvData.length > 0"
        variant="primary"
        class="mb-1 mb-sm-0 add-btn"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        style="margin-top: -20px; margin-right: 20px; font-size: 12px; padding: 4px;"
        @click="handleShowReviewList"
      >
        <template> Review list </template>
      </b-button>
      <b-button
        variant="primary"
        class="mb-1 mb-sm-0 add-btn"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        style="margin-top: -20px;"
        @click="handleShowModal"
      >
        {{ csvData.length > 0 ? 'x' : ' +' }}

      </b-button>

    </b-col>
    <b-modal
      ref="user"
      hide-footer
      title="Group"
      centered
      cancel-variant="outline-secondary"
      size="md"
    >
      <div style="display: flex; gap: 4px; align-items: center; justify-content: space-between;margin-bottom: 10px;">
        <div style="font-size: 16px; font-weight: bold; color: black; margin-bottom: 4px;">
          List selected group
        </div>

      </div>
      <b-row
        class="mb-2"
        style="max-height: 100px; overflow-y: scroll;"
      >

        <b-col
          v-for="(i, ind) in selectedUsers"
          :key="ind"
          md="12"
          style="display: flex; align-items: center; gap: 10px; row-gap: 16px;"
        >
          <div style="min-width: 150px;">

            <b-badge

              pill
              variant="light-success"
            >
              {{ `${i.groupId}. ${i.name}` }}
            </b-badge>
          </div>
          <div
            style="background: red; color: white; font-size: 12px; padding-left: 4px; padding-right: 4px; border-radius: 5px; cursor: pointer; font-weight: bold;"
            @click="handleRemoveUser(i)"
          >
            x
          </div>
        </b-col>
      </b-row>
      <b-row class="text-modal">
        <b-form-input
          v-model="searchQuery"
          type="text"
          placeholder="Search..."
        />
        <b-form-group
          class="container"
          style="margin-top: 10px;"
        >
          <b-form-checkbox
            v-for="item in users"
            :key="item.groupId"
            v-model="selectedUsers"
            name="event-filter-group"
            :value="item"
            class="mb-1 ml-2"
            checked="true"
            :disabled="disabledSelectedUser(item)"
          >
            {{ `${item.groupId}. ${item.name}` }}
          </b-form-checkbox>
        </b-form-group>

      </b-row>

      <div style="display: flex; align-items: center; gap: 10px; justify-content: center;">
        <feather-icon
          v-if="Number(page) > 1"
          icon="ChevronLeftIcon"
          size="18"
          style="color: black; cursor: pointer;"
          @click="handleMinusPage"
        />
        <feather-icon
          v-if="Number(page) === 1"
          icon="ChevronLeftIcon"
          size="18"
          style="color: #D9D9D9;"
        />
        <b-form-input
          id="page"
          v-model="page"
          type="number"
          style="padding: 6px; padding-left: 10px; padding-right: 10px; border-radius: 20px; font-weight: bold; background-color: #2B65A5; width: 50px; color: white; text-align: center;"
          :formatter="formatter"
        />
        <feather-icon
          v-if="Number(page) < totalPage"
          icon="ChevronRightIcon"
          size="18"
          style="color: black; cursor: pointer;"
          @click="handlePlusPage"
        />
        <feather-icon
          v-if="Number(page) === totalPage"
          icon="ChevronRightIcon"
          size="18"
          style="color: #D9D9D9;"
        />
      </div>
      <b-row>
        <b-col class="d-flex justify-content-end ">
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="handleAddUser"
          >

            <template> Add </template>
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

  </b-row>
</template>

<script>
import useToastHandler from '@/services/toastHandler'
import { mapActions, mapState } from 'vuex'
import {
  BCol, BRow, BButton, VBModal, BFormCheckbox,
  BFormGroup, BFormInput, BBadge,
} from 'bootstrap-vue'
import {
  arrayProp, numberProp, stringProp,
} from '@/helpers/props'
// eslint-disable-next-line import/no-extraneous-dependencies

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BBadge,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    value: stringProp(),
    typeNumber: numberProp(),
    selectedList: arrayProp(),
    clientProfileList: arrayProp(),
    listEditProfile: arrayProp(),
    modalType: stringProp(),
  },

  data() {
    return {
      loading: false,
      totalUsers: null,
      users: [],
      page: 1,
      isLoading: false,
      selectedUsers: [],
      searchText: '',
      totalPage: 1,
      timeout: null,

      csvData: [],

      currentPage: 1,
      perPage: 20,
    }
  },
  computed: {
    ...mapState({
      list: s => s.user.list,
    }),
    ...mapState('notification', ['loading', 'isReset']),
    searchQuery: {
      set(val) {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.page = 1
          this.searchText = val
          this.fetchGroups()
        }, 500)
      },
    },
    total() {
      return Math.ceil(this.csvData.length / this.perPage)
    },

  },
  watch: {
    page() {
      this.fetchGroups()
    },
    currentPage() {
      this.dataPerPage()
    },
    isReset(val) {
      if (val) {
        this.selectedUsers = []
        this.csvData = []
        this.resetListUser(false)
      }
    },
  },
  async created() {
    this.users = await this.fetchGroups()
  },

  methods: {
    ...mapActions({
      setPickEvent: 'races/listRace/setPickEvent',
      setPickListEvent: 'races/listRace/setPickListEvent',
      setPickFrontBack: 'races/listRace/setPickFrontBack',
      setPickFrontBackNumber: 'races/listRace/setPickFrontBackNumber',
      asyncUsers: 'user/list/asyncUsers',
      resetListUser: 'notification/resetListUser',
      getListGroup: 'races/listGroup/getListGroup',
    }),
    dataPerPage() {
      const startIndex = (this.currentPage - 1) * this.perPage
      const endIndex = startIndex + this.perPage
      const itemsToShow = this.csvData.slice(startIndex, endIndex)
      return itemsToShow
    },
    async handleShowModal() {
      if (this.csvData.length > 0) {
        this.csvData = []
        return
      }
      this.$refs.user.show()
    },
    handleShowReviewList() {
      this.$refs['list-review'].show()
    },
    handleHideModal() {
      this.$refs.user.hide()
    },
    handleAddUser() {
      this.$emit('update-data', this.selectedUsers)
      this.$refs.user.hide()
      this.searchQuery = ''
    },
    async fetchGroups() {
      if (!this.page) this.page = 1
      const data = await this.getListGroup({
        searchParams: this.searchText,
        limit: 10,
        page: this.page < 1 ? 1 : this.page,
        type: 'punter',
        notStatus: 'close',
      })
      this.totalUsers = data.pagination.total
      this.totalPage = Math.ceil(data.pagination.total / 10)
      this.users = data.data
      return data.data
    },
    async  handlePlusPage() {
      if (this.page <= this.totalPage) {
        this.page++
      }
    },
    async  handleMinusPage() {
      if (this.page > 1) {
        this.page--
      }
    },
    formatter(value) {
      const regexNumber = /^[0-9.]+$/

      if (regexNumber.test(value)) {
        if (value.split('').filter(s => s === '.').length > 1) return
        if (value.includes('.')) {
          const decimalPart = value.split('.')[1]
          const integerPart = value.split('.')[0]

          if (decimalPart.length > 2) {
            return `${integerPart}.${decimalPart.slice(0, 2)}`
          }
        }

        if (Number(value) > this.totalPage) return this.totalPage
        if (Number(value) < 1) return '1'
        return value
      }
      value = value.replace(/[^0-9.]/, '')
      return value
    },
    handleRemoveUser(i) {
      this.selectedUsers = this.selectedUsers.filter(x => x.groupId !== i.groupId)
    },
    disabledSelectedUser(item) {
      return this.listEditProfile.find(i => i.appliableId === item.groupId)
    },
    onClickInput() {
      this.selectedUsers = []
      const fileInput = this.$refs.fileInput
      fileInput.click()
    },
  },

  setup() {
    const { showToastSuccess, showToastError } = useToastHandler()

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    }

    return {
      showToastSuccess,
      showToastError,
      perfectScrollbarSettings,
    }
  },
}
</script>

    <style lang="scss" scoped>
    .add-btn {
      padding: 2px 4px;
    }

    .remove-btn {
      padding: 0px 4px 2px 4px;
      margin-left: 1px;
    }

    .delete-btn {
      color: red;
      font-size: 16px;
      font-weight: 700;
      cursor: pointer;
    }

    .text-modal {
      color: black;
    }

    .modal.modal-footer {
      display: none !important;
    }
    </style>
